import axios from "axios";
import { sleep } from "./util.service";
import Swal from "sweetalert2";
import { useLoading } from "vue-loading-overlay";

const LOADER_TIMEOUT = 350
let loader = useLoading()
let hide = null
let timeoutStruct = {
    count: 1,
}

// eslint-disable-next-line no-unused-vars
const convertLang = (locale) => {
    let lang = null
    switch (locale) {
        case "de":
            lang = "de-DE"
            break
        case "en":
            lang = "en-US"
            break
        default:
            lang = "de-DE"
    }
    return lang
}

export class ApiService {

    constructor(context={}) {
        this.context = context
    }


    showLoader(isHideLoader=false) {
        if(isHideLoader)
            return

        const ignoreLoader = this.context.ignoreLoader
        if(ignoreLoader)
            return

        timeoutStruct.count -= 1
        if(timeoutStruct.count >= 0) {
            if (hide) {
                hide.hide()
            }
            hide = loader.show()
        }
    }

    hideTimeoutCallback(isHideLoader=false) {
        if(isHideLoader)
            return

        const ignoreLoader = this.context.ignoreLoader
        if(ignoreLoader)
            return

        setTimeout(() => {
            timeoutStruct.count += 1
            this.hideLoader()
        }, LOADER_TIMEOUT)
    }

    hideLoader() {
        if(timeoutStruct.count >= 1) {
            if (hide) {
                hide.hide()
            }
            hide = null
        }
    }

    withContext(lang=null, loader=true) {
        const context = {
            ignoreLoader: !loader,
            lang: lang
        }
        return new ApiService(context)
    }
    setAuthorization(config) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers["Accept"] = "application/json";
        config.headers["Authorization"] = "Token " + process.env.VUE_APP_ODOO_API_KEY;
        config.headers["Accept-Language"] = convertLang(this.context.lang);
    }

    callDelete(url, config = {}) {
        this.showLoader()
        this.setAuthorization(config);
        return new Promise((resolve, reject) => {
            axios
                .delete(url, config)
                .then((response) => {
                    resolve(response);
                    this.hideTimeoutCallback()
                })
                .catch((error) => {
                    this.hideTimeoutCallback()
                    if (
                        error.response?.status === 403 ||
                        error.response?.status === 401
                    ) {
                        //accountService.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async _catchError(error, isHideLoader, reject, loader) {
        this.hideTimeoutCallback(isHideLoader)
        if (
            error.response?.status === 403 ||
            error.response?.status === 401
        ) {
            // await accountService.logout();
        } else if (error.response?.status === 400) {
            this.hideTimeoutCallback(!isHideLoader)
            if (loader) {
                loader.hide()
            }
            let message = error.response.data.error ? error.response.data.error : error.response.data
            if (message === null) {
                message = ""
            }
            if (
                typeof message === 'object' &&
                !Array.isArray(message)
            ) {
                let tempMessage = []
                Object.entries(message).forEach(([key, values]) => {
                    let tmpErrors = []
                    values.forEach(val => {
                        tmpErrors.push(val.value)
                    })
                    tempMessage.push(`<b>${key}</b><br/><br/>${tmpErrors.join(", ")}<br/>`)
                })
                message = tempMessage
            }

            if (Array.isArray(message)) {
                message = message.join("<br/>")
            }


            await Swal.fire({
                title: "Fehler",
                html: message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
        reject(error);
    }

    callPost(url, data = {}, config = {}, isHideLoader=false, loader=null) {
        this.showLoader(isHideLoader)
        this.setAuthorization(config);
        return new Promise((resolve, reject) => {
            axios
                .post(url, data, config)
                .then((response) => {
                    resolve(response);
                    this.hideTimeoutCallback(isHideLoader)
                })
                .catch(async (error) => {
                    await this._catchError(error, isHideLoader, reject, loader)
                });
        });
    }

    callPatch(url, data = {}, config = {}, isHideLoader=false, loader=null) {
        this.showLoader(isHideLoader)
        this.setAuthorization(config);
        return new Promise((resolve, reject) => {
            axios
                .patch(url, data, config)
                .then((response) => {
                    resolve(response);
                    this.hideTimeoutCallback(isHideLoader)
                })
                .catch(async (error) => {
                    await this._catchError(error, isHideLoader, reject, loader)
                });
        });
    }

    callGet(url, config = {}) {
        this.showLoader()
        this.setAuthorization(config);
        return new Promise((resolve, reject) => {
            axios
                .get(url, config)
                .then((response) => {
                    resolve(response);
                    this.hideTimeoutCallback()
                })
                .catch(async (error) => {
                    this.hideTimeoutCallback()
                    reject(error);
                    await sleep(300)
                    const status = error.response?.status;
                    if (status === 403 || status === 401) {
                        //accountService.logout();
                    }
                });
        });
    }
}

export const apiService = new ApiService()
