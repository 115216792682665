import {renderComponent, retrieveElementAsyc} from "@/assets/js/renderComponent";
import {scrollToTop} from "@/assets/js/common";
import {ref} from "vue";
export const activeTabs = ref(null)
export const wizardRef = ref(null)

export default async function c(appContext, tabs, wiz, currentTabIndex, iconDefault, wizardTabIndex=0, initialIndex=0) {
    const stepContainers = await retrieveElementAsyc(wiz.value.$el, ".fw-step-container")
    for(const container of stepContainers){
        renderComponent({
            el: container,
            component: iconDefault,
            appContext: appContext
        })
    }
    activeTabs.value = tabs
    currentTabIndex.value = initialIndex;
    await wiz.value.changeTab(wizardTabIndex)
    wizardRef.value = wiz
    scrollToTop()
}