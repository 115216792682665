<template>
<div class="back-navigation pointer">
  <img alt="arrow-back" :src="require('@/assets/icons/arrow_back.svg')">
  <p>{{ t("home.back") }}</p>
</div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "BackNavigationButton",
  setup() {
    const { t } = useI18n()
    return {
      t
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.back-navigation {
  width: 166px;
  border-radius: 10px;
  border: 1px solid #{$color-black};
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 6px 15px 5px 19px;
  margin-bottom: 20px;
}
.back-navigation > p {
  padding-left: 10px;
  padding-bottom: 2px;
}
</style>