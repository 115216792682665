<template>
  <div class="check-box" @click="clickCheck" :class="{'v-error': v$.$error}">
    <img class="check-box__icon" :class="{ checked: state.checked }" :src="require(`@/assets/icons/check.svg`)"/>
    <div class="check-box__info">
      <p>{{ title }}</p>
      <p class="check-box__info-description">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import useVuelidate from "@vuelidate/core";
import {sameAs} from "@vuelidate/validators";

export default {
  name: "CheckBoxComponent",
  components: {
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    modelValue: {
      type: Boolean,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props, {emit}) {
    const state = reactive({
      checked: props.modelValue
    })

    const clickCheck = () => {
      state.checked = !state.checked
      emit("update:modelValue", state.checked)
    }

    const rules = {
      checked: {

      }
    }

    if(props.required){
      rules.checked = {
        ...rules.checked,
        checked: sameAs(true)
      }
    }



    const v$ = useVuelidate(rules, state)

    return {
      state,
      clickCheck,
      v$,
      emit
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.check-box {
  display: flex;
  font-size: 20px;

  &__icon {
    width: 21px;
    padding: 5px;
    height: 21px;
    border-radius: 5px;
    background: white;
    border: 1px solid $color-black;
  }

  &__icon.checked {
    background: $color-black;
  }

  &__info {
    margin-left: 10px;
  }

  &__info-description {
    font-size: 14px;
    margin-top: 4px;
    color: $color-grey-dark;
  }
}

.check-box.v-error .check-box__icon{
  border-color: $color-error;
}

.check-box.v-error .check-box__info {
  color: $color-error;
}

.check-box__info > p:first-of-type {
  margin-top: -4px;
}
</style>