import {ContactViewModel, DeviceViewModel} from "@/assets/js/viewmodels";

const { register } = require('god-tier-serializer');

class DataType {
    _value = null
    name = null
    display = true
    retrievePath = null
    _component = null
    _dataName = null
    required = true
    inputType = null
    options = null
    placeholder = null
    extraInformation = null
    formatCheck = []
    odooModel = null
    constructor({ name=null,
                    display=true,
                    retrievePath=null,
                    value=null,
                    required = true,
                    inputType = "text",
                    placeholder = null,
                    extraInformation = null,
                    odooModel=null,
                    formatCheck = []}) {
        this._value = value;
        this.name = name;
        this.odooModel = odooModel;
        this.formatCheck = formatCheck;
        this.extraInformation = extraInformation;
        this.placeholder = placeholder;
        this.display = display;
        this.required = required
        this.inputType = inputType;
        this.retrievePath = retrievePath;
    }

    set dataName(name) {
        this._dataName = name
    }

    get dataName() {
        return this._dataName
    }

    set component(val) {
        this._component = val
    }

    get component() {
        return this._component
    }

    get realValue() {
        return this._value
    }

    set realValue(val) {
        this._value = val
    }

    get value() {
        return this._value ? this._value : "shared.no-information"
    }

    get isUndefined(){
        return !this._value
    }

    set value(val){
        if(this.inputType === "select") {
            // eslint-disable-next-line no-prototype-builtins
            const defaultValue = val ? [val] : []
            this.options = val && "options" in val ? val["options"] : defaultValue
        }
        this._value = val
    }
}

class MultipleDataTypes {
    items = null
    component = null
    constructor(items, component) {
        this.items = items
        this.component = component
    }

    get first() {
        return this.items?.length > 0 ? this.items[0] : null
    }
}


class Item {
    setItems(data) {
        if(!data) return
        for (const name in this) {

            this.setValue(name, data)
        }
    }

    setValue(name, data) {
        if(!(this[name] instanceof DataType)) return
        let value = data
        const keys = this[name].retrievePath ? this[name].retrievePath.split(".") : [name]
        keys.forEach(key => {
            // eslint-disable-next-line no-prototype-builtins
            value = value && value.hasOwnProperty(key) ? value[key] : null
        })
        this[name].value = value
        this[name].dataName = name
    }

    get data() {
        const data = {}
        for (const name in this) {
            const item = this[name]
            if(item.retrievePath) {
                const storedValue = item.odooModel ? item.realValue?.id : item.realValue
                data[item.retrievePath] = storedValue || null
            }
        }
        return data
    }

    get items() {
        const data = []
        for (const name in this) {
            if(this[name].display) {
                data.push(this[name])
            }
        }
        return data
    }
}

/*const serialnumber = {
    "name": "serialnumber",
    "value": /^[0-9]{2}.[0-9]{3}.[0-9]{3}.[0-9]{1}$/
}*/


const onlynumbers = {
    "name": "onlynumbers",
    "value": /^\d+$/}


class Device extends Item {
    brand = new DataType({
        name: "capture.device.brand",
        retrievePath: "device_brand_id",
        placeholder: "capture.device.brand-placeholder",
        odooModel: "device.brand",
        inputType: "select"
    })
    type = new DataType({
        name: "capture.device.type",
        inputType: "select",
        retrievePath: "device_type_id",
        placeholder: "capture.device.type-placeholder",
        odooModel: "device.type",
        required: false
    })
    modelNr = new DataType({
        name: "capture.device.model-number",
        retrievePath: "device_model_nr",
        required: false
    })
    purchaseDate = new DataType({
        name: "capture.device.purchase-date",
        inputType: "datepicker",
        retrievePath: "warranty_purchase_date",
        placeholder: "XX.XX.20XX",
        required: false
    })
    serialNr = new DataType({
        name: "capture.device.serial-number",
        required: false,
        retrievePath: "device_serial_number",
        extraInformation: "capture.device.extra.serial-number"
    })
    errorDescription = new DataType({
        name: "capture.device.error-description",
        inputType: "textarea",
        retrievePath: "device_error_description",
        required: false
    })
    productNr = new DataType({
        name: "capture.device.product-number",
        display: false,
        retrievePath: "device_product_nr",
        required: false
    })
    inWarranty = new DataType({
        name: "capture.device.in-warranty",
        display: false,
        retrievePath: "in_warranty",
        required: false
    })

    constructor(data)
    {
        super();
        /*this.serialNr.formatCheck = [
            serialnumber
        ]*/


        this.setItems(data)
    }
}



class Contact extends Item {
    name = "capture.contact.name"

    /*Endcustomer = new DataType({
        name: "capture.contact.endcustomer",
        display: false,
        dataType: Boolean
    })*/
    firstName = new DataType({
        name: "capture.contact.firstname",
        retrievePath: "customer_firstname",
        required: false
    })
    lastName = new DataType({
        name: "capture.contact.lastname",
        retrievePath: "customer_lastname",
    })
    street = new DataType({
        name: "capture.contact.street",
        retrievePath: "customer_street"
    })
    zip = new DataType({
        name: "capture.contact.zip",
        retrievePath: "customer_zip",
        formatCheck: [
            onlynumbers
        ]
    })
    city = new DataType({
        name: "capture.contact.locality",
        retrievePath: "customer_city"
    })
    country = new DataType({
        name: "capture.contact.country",
        retrievePath: "country_id",
        placeholder: "capture.contact.country-placeholder",
        odooModel: "res.country",
        inputType: "select",
    })
    phone = new DataType({
        name: "capture.contact.phone",
        retrievePath: "customer_phone",
        inputType: "tel",
        required: false
    })
    phone2 = new DataType({
        name: "capture.contact.secondphone",
        retrievePath: "customer_phone2",
        inputType: "tel",
        required: false
    })
    email = new DataType({
        name: "capture.contact.email",
        retrievePath: "customer_email",
        inputType: "email",
        required: false,
    })


    constructor(data)
    {
        super();
        this.setItems(data)
    }
}

Item.prototype.forEach = function forEach(callback) {
    for (const name in this) {
        callback(this[name], name, this);
    }
};

class ServiceOrderModel {
    device = null;
    customer = null
    orderRef = null
    constructor(serviceOrder) {
        this.orderRef = serviceOrder.order_ref
        this.device = new DeviceViewModel(new Device(serviceOrder))
        this.customer = new ContactViewModel(new Contact(serviceOrder))
    }

    get serviceOrder() {
        return {
            ...this.device.origItems.data,
            ...this.customer.origItems.data,
        }
    }
}

register(DataType.prototype, "DataType")
register(MultipleDataTypes.prototype, "MultipleDataTypes")
register(Item.prototype, "Item")
register(Contact.prototype, "Contact")
register(Device.prototype, "Device")
register(ServiceOrderModel.prototype, "ServiceOrderModel")


export {
    Device,
    Contact,
    MultipleDataTypes,
    ServiceOrderModel
}