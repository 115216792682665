<template>
  <div class="success-icon">
    <div class="success-tip" :class="{animation: showAnimation}"></div>
    <div class="success-long" :class="{animation: showAnimation}"></div>
  </div>
</template>

<script>
export default {
  name: "SuccessIcon",
  props: {
    showAnimation: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.success-tip,
.success-long {
  display: block;
  position: absolute;
  height: 25%;
  background-color: var(--success-icon-color);
 }

.success-tip {
  width: 1px;
  left: 37%;
  top: 58%;
  transform: translate(-50%, -50%) rotate(-50deg);
 }

.success-tip.animation {
  animation: tipInPlace 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 180ms;
  background-color: var(--success-icon-color);
}

.success-long {
  width: 1px;
  top: 43%;
  left: 68%;
  height: 65%;
  transform: translate(-50%, -50%) rotate(40deg);
}

.success-long.animation {
  animation: longInPlace 140ms ease-in-out;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 300ms + 140ms;
  background-color: $color-icon-success;
}

.success-icon {
  display: none;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  --success-icon-color: #{$color-checked};
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes tipInPlace {
  from {
    width: 0;
    top: 0;
    left: -62%;
  }
  to {
    width: 1px;
    left: 35%;
    top: 55%;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    height: 0;
    top: 62%;
    left: 41%;
  }
  to {
    height: 65%;
    top: 40%;
    left: 65%;
    visibility: visible;
  }
}

</style>