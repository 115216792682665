import {apiService, utilService} from "@/services";
import {computed, ref} from "vue";
import {deserialize, serialize} from "god-tier-serializer";

const SERVICE_ORDER_GET_URL = "/order/get";
const SERVICE_ORDER_OPTIONS_URL = "/order/options/get";
const SERVICE_ORDER_STATUS_PATCH_URL = "/order/status/post";
const SERVICE_ORDER_PATCH_URL = "/order/post";
export const orderService = {
    storedData: ref(null),
    data: computed({
        get: () => {
            try {
                const storedData = localStorage.getItem("storedData")
                orderService.storedData.value = storedData ? deserialize(storedData) : null;
            } catch (e) {
                console.error(e);
            }
            return orderService.storedData.value;
        },
        set: (value) => {
            if (!value) {
                localStorage.removeItem("storedData");
                orderService.storedData.value = null;
            } else {
                const fixedValue = Object.fromEntries(Object.entries(value).map(([key, value]) => {
                    if(value instanceof ref){
                        value = value.value
                    }
                    return ([key, value])
                }))
                localStorage.setItem("storedData", serialize(fixedValue));
                orderService.storedData.value = value;
            }
        },
    }),
    pushOrderData() {
        this.data.value = this.storedData.value
    },
    getServiceOrder() {
        return apiService
            .callGet(process.env.VUE_APP_ODOO_API_URL + SERVICE_ORDER_GET_URL, {
                params: {
                    ac:  this.storedData.value?.accessCode
                }
            })
            .then((response) => {
                return response.data;
            }).catch(() => {
                return null
            });
    },
    postServiceOrderStatus(status) {
        const accessCode = this.data.value?.accessCode
        return apiService
            .callPost(process.env.VUE_APP_ODOO_API_URL + SERVICE_ORDER_STATUS_PATCH_URL, {
                status: status,
                ac: accessCode
            })
            .then((response) => {
                if(response.data?.success){
                    orderService.data.value.serviceOrder.status = response.data.status
                    this.pushOrderData()
                }
                return response.data;
            });
    },

    postServiceOrder(service_order_data) {
        const accessCode = this.data.value?.accessCode
        service_order_data.language_iso_code = utilService.locale.value
        service_order_data.extra_comment = "Manuelle Kundenänderung"
        return apiService
            .callPost(process.env.VUE_APP_ODOO_API_URL + SERVICE_ORDER_PATCH_URL, {
                service_order_data: service_order_data,
                ac: accessCode
            })
            .then((response) => {
                return response.data;
            });
    },
    getOptions(content, model, lang, order=null, limit=10) {
        return apiService.withContext(lang, false)
            .callGet(process.env.VUE_APP_ODOO_API_URL + SERVICE_ORDER_OPTIONS_URL, {
                params: {
                    ac:  this.storedData.value?.accessCode,
                    content: content,
                    model: model,
                    order: order,
                    limit: limit
                }
            })
            .then((response) => {
                return response.data;
            });
    }
};
