<template>
  <div class="custom-search-select" :class="{'v-error': v$.$error}">
    <VueSingleSelect :value="state.value"
                     :option-label="labelBy"
                     :input-id="odooModel"
                     :options="localOptions.filter(op => op[valueBy])"
                     :placeholder="placeholder"
                     @input="changeValue">
    </VueSingleSelect>
    <div v-if="v$.$error" class="custom-input-error">
      {{ t(`errors.${v$.$errors[0].$validator}`) }}
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import VueSingleSelect from "vue-single-select";
import {orderService} from "@/services";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "CustomSearchSelect",
  components: {
    VueSingleSelect
  },
  props: {
    modelValue: {
      type: Object
    },
    options: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: () => false
    },
    labelBy: {
      type: String,
      default: () => "name"
    },
    valueBy: {
      type: String,
      default: () => "id"
    },
    placeholder: {
      type: String,
      default: () => "Suche"
    },
    odooModel: {
      type: String,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n()
    //const value = ref(props.modelValue)

    const state = reactive({
      value: null,
    })

    const localOptions = ref(props.options)
    const changeValue = (ev) => {
      const isInputEvent = ev instanceof InputEvent || ev instanceof Event
      let searchValue = ev
      if(isInputEvent) {
        searchValue = ev?.target?.value
        getOptions(searchValue).then(options => {
          localOptions.value = options
        })
      }else {
        state.value = searchValue
        emit('update:modelValue', searchValue)
      }
    }

    const getOptions = async (content=null) => {
      let options = props.options
      if(props.odooModel) {
        options = await orderService.getOptions(content, props.odooModel, locale.value)
      }
      return options
    }


    const rules = {
      value: {
      }
    }

    if(props.required) {
      rules.value = {
        ...rules.value,
        required,
      }
    }

    const v$ = useVuelidate(rules, state)

    onMounted(() =>  {
      getOptions().then(options => {
        localOptions.value = options
        state.value = props.modelValue
      })
    })

    watch(() => v$.value.$error, (hasError) => {
      emit('error', hasError)
    })

    return {
      emit,
      changeValue,
      localOptions,
      t,
      v$,
      state
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";
.custom-search-select .search-input {
  border: 1px solid $color-grey-dark;
  border-radius: 10px;
  padding: var(--select-padding);
  font-size: var(--select-font-size);
  color: $color-black;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: 1;
  background: white url("@/assets/icons/arrow_down.svg") no-repeat right var(--select-icon-right) top 50%;
}

.custom-search-select.v-error .search-input {
  border: 1px solid $color-error;
}

.custom-search-select .single-select-wrapper {
  margin-bottom: 0;
}

.custom-search-select .icons {
  display: none;
}

.custom-search-select > div:first-of-type {
  flex: 1;
}

.custom-search-select .search-input::placeholder {
  color: $color-grey-dark;
  font-size: var(--select-font-size);
  opacity: 0.8;
}
.custom-search-select .dropdown li {
  padding: var(--select-padding);
  font-size: var(--select-font-size);
  border-radius: 0;
  color: $color-black;
}

.custom-search-select .dropdown {
  border-radius: 0;
  border: 1px solid $color-black;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.custom-search-select {
  display: flex;
  //border: 1px solid $color-grey-dark;
  border-radius: 10px;
  flex-direction: column;
  --select-font-size: 17px;
  --select-padding: 6px 5px 6px 13px;
  --select-icon-right: 12px;
  font-weight: 400;
  position: relative;
  flex: 1;
}


</style>