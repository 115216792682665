<template>
  <FooterComponent v-if="edit" :next="next" :btn-text="t('shared.confirm')">
    <div v-if="error" class="custom-input-error capture-footer-error">
      {{t("capture.error-global")}}
    </div>
  </FooterComponent>
</template>

<script>
import {useI18n} from "vue-i18n";
import FooterComponent from "@/components/shared/FooterComponent";

export default {
  name: "CaptureFooterComponent",
  components: {
    FooterComponent
  },
  props: {
    edit: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: Boolean,
      default: () => false
    },
    next: {
      type: String,
      default: () => null
    }
  },
  setup() {
    return {
      ...useI18n()
    }
  }
}
</script>

<style lang="scss" scoped>
.capture-footer-error {
  padding: 8px;
}
.footer {
  flex-direction: column;
  --footer-height: 80px;
}
</style>