<template>
  <div id="phone-input">
    <MazPhoneNumberInput
        v-model="state.value"
        :placeholder="placeholder"
        show-code-on-list
        :default-country-code="countryCode"
        :translations="{
          countrySelector: {
            searchPlaceholder: t('shared.phone-search-placeholder')
         }
        }"
        @update="state.result = $event"
    />
    <div v-if="v$.$error" class="custom-input-error">
      {{ t(v$.$errors[0].$message) }}
    </div>
  </div>
</template>

<script>
import {computed, reactive, watch} from "vue";
import {useI18n} from "vue-i18n";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "CustomPhoneInput",
  components: {
    MazPhoneNumberInput
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null
    },
    countryCode: {
      type: String,
      default: () => "AT"
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const state = reactive({
      value: !props.modelValue.isUndefined ? props.modelValue?.value : null,
      result: null
    })

    const changedPhoneNumber = (val) => {
      const newModelvalue = props.modelValue
      newModelvalue.value = val
      emit('update:modelValue', newModelvalue)
    }


    watch(() => state.value, (newVal) => {
      changedPhoneNumber(newVal)
    })

    const mustBeValid = (value, siblings) => {
      return !helpers.req(siblings.value) || value.isValid
    }


    const rules = {
      value: {

      },
      result: {
        validator: helpers.withMessage('errors.phone', mustBeValid)
      }
    }

    if(props.modelValue.required) {
      rules.value = {
        ...rules.value,
        required,
      }
    }

    const v$ = useVuelidate(rules, state)

    watch(() => v$.value.$error, (hasError) => {
      const newModelvalue = props.modelValue
      newModelvalue.error = hasError
      emit('update:modelValue', newModelvalue)
    })

    const placeholder = computed(() => {
      return props.modelValue?.placeholder ? props.modelValue?.placeholder : t(props.modelValue?.name)
    })



    return {
      state,
      placeholder,
      t,
      v$,
      changedPhoneNumber
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";
#phone-input .m-phone-number-input {
  border-radius: 10px;
  border-color: $color-black;
  color: $color-black;
  font-size: 22px;
  box-shadow: none;
}

#phone-input .m-phone-number-input .m-select .m-input-wrapper {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-width: 1px;
  border-color: $color-black;
}

#phone-input .m-phone-number-input .m-select .m-input-wrapper .m-input-wrapper-input::after {
  content: "";
  right: 10px;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  background: url("@/assets/icons/arrow_down.svg") left center/10px no-repeat;
  transform: translateY(-50%);
}

#phone-input .m-phone-number-input .m-select.--is-open .m-input-wrapper .m-input-wrapper-input::after {
  //transform: translateY(-50%) rotate(180deg);
}

#phone-input .m-phone-number-input .m-input:not(.m-select-input) .m-input-wrapper{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 1px 1px 0;
  border-color: $color-black;
}

#phone-input .m-phone-number-input .m-input:not(.m-select-input).--should-up .m-input-wrapper .m-input-label {
  display: none;
}
#phone-input .m-phone-number-input__input {
  padding-top: 6px !important;
  padding-bottom: 6px;
}

#phone-input .m-select.m-phone-number-input__select {
  width: 100px !important;
}

#phone-input .m-input-wrapper-right {
  display: none;
}


#phone-input .m-phone-number-input .m-select .m-input-wrapper .m-input-label {
  display: none;
}

#phone-input .m-select-input {
  padding-top: 6px;
  padding-bottom: 6px;
}

#phone-input .m-phone-number-input__select .m-input-wrapper .m-select-input {
  padding-left: 38px !important;
  position: relative;
  flex: 1;
}

#phone-input .m-phone-number-input__country-flag {
  bottom: 50%;
  left: 13px;
  transform: translateY(50%);
}

#phone-input .m-phone-number-input__input {
  flex: 1;
}

#phone-input .m-phone-number-input li.vti__dropdown-item {
  font-size: 12px;
}

#phone-input .m-phone-number-input .m-select-list {
  background-color: white;
}

#phone-input .m-phone-number-input .m-select-list .m-phone-number-input__select__item .maz-w-10 {
  display: inline-block;
  width: 35px;
}

#phone-input .m-phone-number-input .m-select-list .m-input-wrapper-input::after {
  display: none;
}

#phone-input .m-phone-number-input .m-select-list .m-input-wrapper {
  border-width: 1px !important;
}

#phone-input .m-input.--md {
  height: auto !important;
  padding: 0 !important;
}

</style>

<style lang="scss" scoped>

#phone-input {
  display: flex;
  flex-direction: column;
  flex: 1;
}

</style>
