<template>
  <div id="home">
    <Wizard
        id="wizard"
        ref="wizard"
        :key="`wizard-${rerenderWizard}`"
        :custom-tabs="customTabs"
    >
      <template v-for="(tab, index) in activeTabs">
        <component :isEdit="isEdit" @onEdit="onEdit"
                   v-model="serviceOrderModel" v-if="index === currentTabIndex"
                   :is="tab.component"
                   :key="`${tab.component}-${changedView}`"
                   @hasError="hasError"
                   @back="back"/>
      </template>

      <template #footer>
        <template v-for="(tab, index) in activeTabs">
          <component v-if="index === currentTabIndex" :edit="isEdit" @next="nextTab" :next="tab.next"
                     :error="error"
                     :is="tab.footer_component"
                     :key="`${tab.footer_component}-${changedView}`"/>
        </template>
      </template>
    </Wizard>

  </div>
</template>

<script>
import 'form-wizard-vue3/dist/form-wizard-vue3.css';
import Wizard from "form-wizard-vue3";
import {computed, defineAsyncComponent, getCurrentInstance, nextTick, onMounted, ref, watch} from "vue";
import CaptureComponent from "@/components/capture/CaptureComponent";
import ExaminationComponent from "@/components/examination/ExaminationComponent";
import SubmissionComponent from "@/components/submission/SubmissionComponent";
import SuccessIcon from "@/components/icons/SuccessIcon";
import {useI18n} from "vue-i18n";
import CaptureFooterComponent from "@/components/capture/CaptureFooterComponent";
import {ServiceOrderModel} from "@/assets/js/models";
import ExaminationFooterComponent from "@/components/examination/ExaminationFooterComponent";
import {scrollToTop} from "@/assets/js/common";
import useTab from "@/components/mixins/useTab";
import initTabs from "@/components/mixins/initTabs";
import {orderService} from "@/services";


export default {
  name: 'CaptureView',
  components: {
    Wizard,
    CaptureComponent,
    ExaminationComponent,
    SubmissionComponent,
    SuccessIcon,
    CaptureFooterComponent,
    ExaminationFooterComponent
  },
  setup() {
    const { t, locale } = useI18n()
    const changedView = ref(0)
    const rerenderWizard = ref(0)
    const wizard = ref(null)
    const error = ref(false)
    const iconComponent = computed(() => {
      return defineAsyncComponent(() => import("@/components/icons/SuccessIcon"))
    })

    const hasError = (err) => {
      error.value = err
    }

    const { appContext } = getCurrentInstance()
    const serviceOrder = orderService.data.value.serviceOrder
    let orderModel = orderService.data.value.serviceOrderModel
    if(!orderModel) {
      orderModel = new ServiceOrderModel(serviceOrder)

    }

    const serviceOrderModel = ref(orderModel)
    orderService.data.value.serviceOrderModel = serviceOrderModel

    const activeTabs = [
      {
        "footer_component": "capture-footer-component",
        "component": "capture-component",
        "title": "home.title.capture",
        "next": "examination"
      },
      {
        "footer_component": "examination-footer-component",
        "component": "examination-component",
        "title": "home.title.examination",
        "next": "submission"
      },
      {
        "footer_component": "",
        "component": "submission-component",
        "title": "home.title.submission",
        "next": "process"
      }
    ]


    const { nextTab, currentTabIndex, isEdit } = useTab()

    const mapTitle = () => { return activeTabs.map(tab => { return { title: t(tab.title) } }) }
    const customTabs = ref(mapTitle())

    const onEdit = async (edit) => {
      isEdit.value = edit
      await nextTick()
      scrollToTop()
    }

    const back = (edit) => {
      error.value = false
      const index = currentTabIndex.value - 1
      isEdit.value = edit
      initTabs(appContext, activeTabs, wizard, currentTabIndex, iconComponent.value, index, index)
    }

    const getWizardTabIndex = () => {
      const status = orderService.data.value?.serviceOrder?.status
      if(status === "checking"){
        return 1
      }
      return 0
    }

    watch(currentTabIndex, async (index) => {
      changedView.value = index >= activeTabs.length ? index - 1 : index
    })

    watch(locale, async () => {
      customTabs.value = mapTitle()
      rerenderWizard.value += 1
      const index = currentTabIndex.value + 1 >= activeTabs.length ? activeTabs.length : currentTabIndex.value
      await nextTick()
      await initTabs(appContext, activeTabs, wizard, currentTabIndex, iconComponent.value, index, currentTabIndex.value)
    })
    onMounted(async () => {
      const index = getWizardTabIndex()
      await initTabs(appContext, activeTabs, wizard, currentTabIndex, iconComponent.value, index, index)
    })

    return {
      currentTabIndex,
      activeTabs,
      wizard,
      isEdit,
      onEdit,
      customTabs,
      nextTab,
      changedView,
      serviceOrderModel,
      rerenderWizard,
      hasError,
      error,
      back
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/wizard.scss";
#home {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
