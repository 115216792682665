<template>
  <div id="textarea">
    <div class="custom-textarea">
      <textarea v-model="value" :placeholder="t(modelValue.name)" :maxlength="maxLength"/>
    </div>
    <span>{{value.length}} / {{maxLength}}</span>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "CustomInput",
  props: {
    modelValue: {
      type: Object,
      default: () => null
    },
    maxLength: {
      type: Number,
      default: () => 4000,
    }
  },
  setup(props, { emit }) {
    const value = ref(!props.modelValue.isUndefined ? props.modelValue?.value : "")
    watch(value, (newValue) => {
      const newModelvalue = props.modelValue
      newModelvalue.value = newValue
      emit('update:modelValue', newModelvalue)
    })
    return {
      emit,
      value,
      ...useI18n()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
#textarea {
  flex: 1;
}

#textarea > span {
  font-size: 16px;
  float: right;
  color: $color-grey-dark
}

.custom-textarea {
  border: 1px solid $color-grey-dark;
  border-radius: 10px;
  --textarea-font-size: 15px;
  --textarea-padding: 12px;
  height: 180px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.custom-textarea > textarea {
  width: 100%;
  height:100%;
  border: none;
  border-radius: 10px;
  line-height: 1.6;
  padding: var(--textarea-padding);
  margin: 0;
  font-size: var(--textarea-font-size);
  resize: none;
}

</style>