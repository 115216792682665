import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import i18n from "@/i18n";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const app = createApp(App)

app.component("VueDatePicker", VueDatePicker)
app.use(router)
app.use(i18n)

app.mount('#app')
