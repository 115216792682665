import i18n from "@/i18n/index"

const Trans = {
    get supportedLocales() {
        return process.env.VUE_APP_VITE_SUPPORTED_LOCALES.split(",")
    },
    set currentLocale(newLocale) { // <--- 2
        i18n.global.locale.value = newLocale
    },
}
export default Trans