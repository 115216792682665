import {nextTick, ref} from 'vue'
import {scrollToFirstError, scrollToTop} from "@/assets/js/common";
import useVuelidate from "@vuelidate/core";
import {orderService, sleep} from "@/services";
import {activeTabs, wizardRef} from "@/components/mixins/initTabs";

const currentTabIndex = ref (null)

export default function useTab(initialEdit=false){


    const isEdit = ref(initialEdit)
    const v$ = useVuelidate()
    // eslint-disable-next-line no-unused-vars
    const onChangeCurrentTab = async (index, _) => {
        const wizard = wizardRef.value
        if(!index || !activeTabs.value || !wizard) return
        if(currentTabIndex.value !== index.value) {
            isEdit.value = false
        }
        currentTabIndex.value = index.value;

        if(index.value + 1 >= activeTabs.value.length){
            await nextTick()
            await wizard.value.changeTab(activeTabs.value.length)
        }
    }

    const nextTab = async (nextView) => {
        const wizard = wizardRef.value
        if(!activeTabs.value || !wizard) return
        const result = await v$.value.$validate()
        if (!result){
            scrollToFirstError()
            return
        }
        isEdit.value = false;

        if(nextView === "examination"){
            await orderService.postServiceOrderStatus("checking")
            currentTabIndex.value = 1
        }else if(nextView === "submission") {
            // TODO error handling, refresh service order!
            const orderModel = orderService.data.value.serviceOrderModel
            const response = await orderService.postServiceOrder(orderModel.serviceOrder)

            console.log(response)
            currentTabIndex.value = 2
            //orderService.data.value = null
        }else {
            currentTabIndex.value += 1;
        }


        let nextTabIndex = currentTabIndex.value
        if(currentTabIndex.value + 1 >= activeTabs.value.length){
            nextTabIndex = activeTabs.value.length
        }
        orderService.pushOrderData()
        await sleep(300)
        wizard.value.changeTab(nextTabIndex)
        scrollToTop()
    }
    return {
        onChangeCurrentTab,
        currentTabIndex,
        isEdit,
        nextTab,
        v$
    }
}
