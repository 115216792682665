<template>
  <div class="custom-select">
    <select v-model="value[valueBy]">
      <option
          v-for="option in options"
          :key="`locale-${option[labelBy]}`"
          @change="(ev) => emit('change', ev)"
          :value="option[valueBy]"
      >{{ translate ? t(option[labelBy]) : option[labelBy] }}</option>
    </select>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "CustomSelect",
  props: {
    modelValue: {
      type: Object
    },
    options: {
      type: Array,
      default: () => []
    },
    labelBy: {
      type: String,
      default: () => "name"
    },
    valueBy: {
      type: String,
      default: () => "id"
    },
    translate: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const value = ref(props.modelValue)
    watch(value, (newValue) => {
      newValue["options"] = props.options
      emit('update:modelValue', newValue)
    })
    return {
      emit,
      t,
      value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.custom-select {
  display: flex;
  border: 1px solid $color-grey-dark;
  border-radius: 10px;
  --select-font-size: 17px;
  --select-padding: 6px 5px 6px 13px;
  --select-icon-right: 12px;
  font-weight: 400;
  flex: 1;
}

select {
  width: 100%;
  height:100%;
  border: none;
  border-radius: 10px;
  padding: var(--select-padding);
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: white url("@/assets/icons/arrow_down.svg") no-repeat right var(--select-icon-right) top 50%;
  font-size: var(--select-font-size);
}
</style>