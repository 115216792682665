<template>
  <div :key="valueObj.name" >
    <p class="content-title" :class="{edit: isEdit, required: valueObj.required, 'v-error': valueObj.error }">{{ t(valueObj.name) }}</p>
    <div v-if="!isEdit" class="content-container" :class="{textarea: valueObj.inputType === 'textarea'}">
      <p>{{ getValue(valueObj) }}</p>
      <template v-if="valueObj.isUndefined">
        <img class="attention" v-if="valueObj.required" alt="attention" :src="require(`@/assets/icons/attention_filled.svg`)"/>
        <img class="question" v-else alt="question" :src="require(`@/assets/icons/question_mark_filled.svg`)"/>
      </template>
    </div>
    <div v-else class="content-container" :class="{extra: valueObj.extraInformation}">
      <CustomSearchSelect v-if="valueObj.inputType === 'select'"  v-model="valueObj.realValue"
                          @error="(err) => valueObj.error = err"
                          :options="valueObj.options" :odooModel="valueObj.odooModel"
                          :placeholder="t(valueObj.placeholder)" :required="valueObj.required"/>
      <CustomTextArea class="content-container__textarea" v-else-if="valueObj.inputType === 'textarea'" v-model="valueObj" />
      <CustomDatePicker v-else-if="valueObj.inputType === 'datepicker'" v-model="valueObj" />
      <CustomPhoneInput v-else-if="valueObj.inputType === 'tel'" v-model="valueObj"  />
      <CustomInput v-else v-model="valueObj"/>
      <div class="content-container__extra"  v-if="valueObj.extraInformation">
        {{ t(valueObj.extraInformation) }}
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import CustomInput from "@/components/shared/CustomInput";
import {ref, watch} from "vue";

import CustomTextArea from "@/components/shared/CustomTextArea";
import CustomDatePicker from "@/components/shared/CustomDatePicker";
import CustomPhoneInput from "@/components/shared/CustomPhoneInput";
import CustomSearchSelect from "@/components/shared/CustomSearchSelect";
import {orderService} from "@/services";
import {enFormat, getFormat} from "@/assets/js/common";
import moment from "moment/moment";

export default {
  name: "ContentComponent",
  components: {
    CustomSearchSelect,
    CustomPhoneInput,
    CustomInput,
    CustomTextArea,
    CustomDatePicker
  },
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n()
    const valueObj = ref(props.modelValue)
    watch(() => valueObj.value.realValue, () => {
      emit('update:modelValue', valueObj.value)
      orderService.pushOrderData()
    })
    const getValue = (valueObj) => {
      let retVal = valueObj.isUndefined ? t(valueObj.value) : valueObj.value
      if(!valueObj.isUndefined && valueObj.inputType === "select"){
        retVal = retVal["name"]
      }

      if(!valueObj.isUndefined && valueObj.inputType === "datepicker") {
        retVal = moment(retVal, enFormat.toUpperCase()).format(getFormat(locale.value).toUpperCase())
      }
      return retVal
    }

    return {
      valueObj,
      getValue,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.content-container {
  display: flex;
  font-size: 22px;
  line-height: 1;

  &__textarea {
    flex-direction: column;
  }

  &__extra {
    margin-top: 10px;
    font-size: 14px;
    color: $color-grey-dark;
  }
}

.content-container.extra {
  flex-direction: column;
  line-height: 1.2;
}

.content-container.textarea {
  line-height: 1.4;
}

.content-container > .attention {
  margin-left: 4px;
  display: flex;
  align-self: end;
}

.content-container > .question {
  margin-left: 4px;
  display: flex;
  align-self: center;
  margin-top: 4px;

}

.content-title {
  color: $color-grey-light;
  font-size: 20px;
  margin-top: 15px;
  line-height: 1;
}

.content-title.edit {
  font-size: 18px;
  margin-bottom: 2px;
}
.content-title.edit.required:after {
  content: "*";
}

.content-title.v-error {
  color: $color-error;
}
</style>