<template>
  <div class="input-container">
    <div class="custom-input" :class="{ warning: modelValue.isUndefined && modelValue.required && !v$.$error, 'v-error': v$.$error }">
      <input :type="modelValue.inputType" v-model="state.value" :placeholder="placeholder" autocomplete="off"/>
      <img v-if="modelValue.isUndefined && modelValue.required && !v$.$error" alt="attention" :src="require(`@/assets/icons/attention_filled.svg`)"/>
    </div>
    <div v-if="v$.$error" class="custom-input-error">
      {{ t(`errors.${v$.$errors[0].$validator}`) }}
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import {email, helpers, required} from '@vuelidate/validators'

export default {
  name: "CustomInput",
  props: {
    modelValue: {
      type: Object,
      default: () => null
    }
  },
  setup(props, { emit }) {
    //const value = ref(!props.modelValue.isUndefined ? props.modelValue?.value : null)
    const { t } = useI18n()
    const formatCheck = ref(props.modelValue.formatCheck)
    const placeholder = computed(() => {
      return props.modelValue?.placeholder ? props.modelValue?.placeholder : t(props.modelValue?.name)
    })

    const state = reactive({
      value: !props.modelValue.isUndefined ? props.modelValue?.value : null
    })

    const rules = {
      value: {  }, // Matches state.firstName
    }

    if(props.modelValue.required) {
      rules.value = {
        ...rules.value,
        required
      }
    }

    if(formatCheck.value?.length) {
      formatCheck.value.forEach(reg => {
        rules.value[reg.name] = helpers.regex(reg.value)
      })
    }

    if(props.modelValue.inputType === "email"){
      rules.value = {...rules.value, email}
    }


    const v$ = useVuelidate(rules, state)
    watch(() => state.value, (newValue) => {
      const newModelvalue = props.modelValue
      newModelvalue.value = newValue
      emit('update:modelValue', newModelvalue)
    })

    watch(() => v$.value.$error, (hasError) => {
      const newModelvalue = props.modelValue
      newModelvalue.error = hasError
      emit('update:modelValue', newModelvalue)
    })

    return {
      emit,
      state,
      v$,
      t,
      placeholder
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.custom-input {
  position: relative;
  border: 1px solid $color-grey-dark;
  border-radius: 10px;
  --input-font-size: 17px;
  --input-padding: 6px 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex: 1;
}

.custom-input > img {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.custom-input > input {
  width: 100%;
  height:100%;
  border: none;
  border-radius: 10px;
  padding: var(--input-padding);
  margin: 0;
  font-size: var(--input-font-size);
}

.custom-input.warning {
  border-color: $color-beige;
}

.custom-input.v-error {
  border-color: $color-error;
}

.input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

</style>