<template>
<div class="capture">
  <slot name="header" />
  <template v-if="!isExamination">
    <LanguageSwitcher class="capture__language-switcher" v-if="!isEdit" />
  </template>
  <p >{{ isEdit ? t(`${prefix}.edit.title`) : t(`${prefix}.title`)}}</p>
  <p class="capture__description">{{ t(`${prefix}.description`)}}</p>
  <p class="capture__device">{{ t(serviceOrder.customer.name) }}</p>
  <div v-for="(contactInformation, index) in serviceOrder.customer.items" :key="contactInformation.name" >
    <component :is="contactInformation.component" :isEdit="isEdit" v-model="serviceOrder.customer.items[index]" />
  </div>

  <p class="capture__device">{{ t(serviceOrder.device.name) }}</p>
  <div v-for="(deviceInformation, index) in serviceOrder.device.items" :key="deviceInformation.name" >
    <component :is="deviceInformation.component" :isEdit="isEdit" v-model="serviceOrder.device.items[index]" />
  </div>

  <CheckBoxComponent class="pointer" v-if="!isExamination"
                     :title="t('capture.warranty')"
                     :description="t('capture.warranty-description')"
                      v-model="serviceOrder.device.items.inWarranty.realValue" />

  <ClickBoxComponent class="pointer" v-if="!isEdit && !isExamination" :positive-name="t('capture.finish')"
                     :negative-name="t('capture.edit-text')"
                     @onPositiveClick="switchExamination"
                     @onNegativeClick="editContent"/>

</div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import ContentComponent from "@/components/shared/ContentComponent";
import RowContentComponent from "@/components/shared/RowContentComponent";
import SuccessIcon from "@/components/icons/SuccessIcon";
import LanguageSwitcher from "@/components/shared/LanguageSwitcher";
import ClickBoxComponent from "@/components/shared/ClickBoxComponent";
import CheckBoxComponent from "@/components/shared/CheckBoxComponent";
import {orderService} from "@/services";
import useTab from "@/components/mixins/useTab";


export default {
  name: "CaptureComponent",
  components: {
    CheckBoxComponent,
    ClickBoxComponent,
    LanguageSwitcher,
    ContentComponent,
    RowContentComponent,
    SuccessIcon
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    isExamination: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props, { emit }) {

    const prefix = props.isExamination ? "examination" : "capture"
    const serviceOrder = ref(props.modelValue)
    const { nextTab, v$ } = useTab()
    watch(serviceOrder, (obj) => {
      emit('update:modelValue', obj)
    })

    const switchExamination = () => {
      nextTab("examination")
    }

    const editContent = () => {
      orderService.postServiceOrderStatus("processing_customer")
      emit("onEdit", !props.isEdit)
    }

    onMounted(() => {
      const status = orderService.data.value?.serviceOrder?.status
      if(status === "processing_customer"){
        emit("onEdit", true)
      }
      emit("hasError", false)
    })

    watch(() => v$.value.$error, (error) => {
      emit("hasError", error)
    })

    return {
      serviceOrder,
      editContent,
      prefix,
      switchExamination,
      v$,
      ...useI18n()
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.capture .success-icon {
  --success-icon-color: #000000;
}
.capture .success-tip, .success-long {
  animation: none !important;
  visibility: visible !important;
}

.capture, {
  text-align: left;
  font-size: 24px;
  color: $color-black;

  &__language-switcher {
    margin-bottom: 30px;
  }

  &__description {
    font-size: 16px;
    margin-top: 17px;
    color: $color-grey-dark;
  }

  &__zip {
    width: 100px;
    margin-right: 8px;
  }

  &__contact {
    margin-top: 23px;
  }

  &__device {
    margin-top: 37px;
  }
}

.check-box {
  margin-top: 25px;
}

</style>